import { ExtrasInfo, FormFields } from 'types/types'
import { Field, useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { fetchExtras } from 'utils/requests'
import { ChangeEvent } from 'react'
import { formatPrice } from 'utils/utils'

interface AddExtrasProps {
  extrasFields: FormFields[]
}

export const AddExtras = ({ extrasFields }: AddExtrasProps) => {
  const { data: extras } = useQuery<ExtrasInfo[]>('extras', fetchExtras)
  const { setFieldValue } = useFormikContext<any>()

  const handleChange = (e: ChangeEvent<HTMLSelectElement>, name: string) => {
    const { value } = e.target
    setFieldValue(name, +value)
    setFieldValue('extrasTotal', extras?.find(m => m.id === +value)?.price ?? 0)
  }

  const filteredExtras = extras?.filter(extra => extra.price !== 0)
  return (
    <div className='mt-40'>
      <div className='flex flex-col gap-10 justify-between mb-40 lg:mb-0'>
        {extrasFields.map(field => {
          if (!field.included) return null

          return (
            <div key={field.name} className='h-64 w-full'>
              <div className='block w-full'>
                <label className='font-title text-textGray lg:text-yellow w-150 my-auto text-14 lg:text-16'>
                  Video
                </label>
                <Field
                  defaultValue={''}
                  className='w-full h-44 px-8 max-w-169 border bg-black font-body focus:outline-none focus:border-yellow text-white block border-white rounded text-16'
                  name={`extras.${field.name}`}
                  as={field.type}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    handleChange(e, `extras.${field.name}`)
                  }
                >
                  <option value='' disabled>
                    {field.placeholder}
                  </option>
                  {field.name === 'id' &&
                    filteredExtras?.map(extra => (
                      <option key={extra.id} value={extra.id}>
                        {extra.name} ({formatPrice(extra.price)} Kč)
                      </option>
                    ))}
                </Field>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
