interface IconProps {
  fill?: string
}

export const Socks = ({ fill }: IconProps) => {
  return (
    <svg
      height='32px'
      viewBox='0 0 513 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_10)'>
        <path
          d='M176.01 476.6C166.31 458.6 160.81 437.9 160.81 416C160.81 375.7 179.81 337.8 212.01 313.6L276.01 265.6C284.11 259.6 288.81 250.1 288.81 240V96H128.81V240C128.81 260.1 119.31 279.1 103.21 291.2L39.21 339.2C15.01 357.3 0.809998 385.8 0.809998 416C0.809998 469 43.81 512 96.81 512C117.61 512 137.81 505.3 154.41 492.8L176.01 476.6ZM128.81 64H288.81V48C288.81 33.5 292.71 19.8 299.51 8.1C291.81 3 282.71 0 272.81 0H176.81C150.31 0 128.81 21.5 128.81 48V64ZM320.81 96V240C320.81 260.1 311.31 279.1 295.21 291.2L231.21 339.2C207.01 357.3 192.81 385.8 192.81 416C192.81 469 235.81 512 288.81 512C309.61 512 329.81 505.3 346.41 492.8L461.61 406.4C493.81 382.2 512.81 344.3 512.81 304V96H320.81ZM512.81 64V48C512.81 21.5 491.31 0 464.81 0H368.81C342.31 0 320.81 21.5 320.81 48V64H512.81Z'
          fill={fill || 'black'}
        />
      </g>
      <defs>
        <clipPath id='clip0_1_10'>
          <rect
            width='512'
            height='512'
            fill='white'
            transform='translate(0.809998)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
