import { Table } from '@tanstack/react-table'
import { AdminButton } from './AdminButton'
import { Select } from '@chakra-ui/react'
import clsx from 'clsx'

interface TablePaginationProps<T extends {}> {
  table: Table<T>
}

export const TablePagination = <T extends {}>({
  table,
}: TablePaginationProps<T>) => {
  const currentPage = table.getState().pagination.pageIndex + 1

  return (
    <div className='flex justify-between mt-30 lg:mt-20 items-center gap-10 sticky left-0'>
      <AdminButton
        title='Předchozí'
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      />

      <div className='flex items-center gap-20'>
        <p className='font-title text-white text-center text-14 lg:text-16 hidden lg:block'>
          Stránka {currentPage} z {table.getPageCount()}
        </p>
        <RecordsSelect table={table} />
      </div>

      <AdminButton
        title='Další'
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      />
    </div>
  )
}

interface RecordsSelectProps<T extends {}> {
  table: Table<T>
}

const RecordsSelect = <T extends {}>({ table }: RecordsSelectProps<T>) => {
  return (
    <div className={clsx('flex items-center gap-10')}>
      <span className='font-title text-white text-center text-14 lg:text-16'>
        Počet:
      </span>
      <Select
        value={table.getState().pagination.pageSize}
        onChange={e => {
          table.setPageSize(Number(e.target.value))
        }}
        borderColor={'#ffea00'}
        className='text-white font-title'
      >
        {[5, 10, 20, 30, 40, 50, 100].map(pageSize => (
          <option
            key={pageSize}
            value={pageSize}
            className='text-14 lg:text-16'
          >
            {pageSize}
          </option>
        ))}
      </Select>
    </div>
  )
}
