import { Form } from 'formik'
import { useContext } from 'react'
import {
  FormNavigationContext,
  FormNavigationProvider,
} from 'context/VoucherFormNavigationContext'
import { AnimatePresence } from 'framer-motion'
import clsx from 'clsx'
import { Formik } from 'formik'

import { orderSchema } from 'utils/validation-schemas'

const initialValues = {
  hasVoucher: false,
  lessonType: [],
  customer: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  },
}

export const VoucherPurchaseForm = () => {
  return (
    <FormNavigationProvider>
      <Formik
        initialValues={initialValues}
        validationSchema={orderSchema}
        onSubmit={values => {}}
      >
        {() => {
          return <VoucherPurchase />
        }}
      </Formik>
    </FormNavigationProvider>
  )
}

export const VoucherPurchase = () => {
  const { currentStepIndex, formSteps } = useContext(FormNavigationContext)

  return (
    <Form>
      <AnimatePresence>
        <div className={clsx(currentStepIndex === 7 ? 'bg-white' : 'bg-black')}>
          {formSteps[currentStepIndex]}
        </div>
      </AnimatePresence>
    </Form>
  )
}
