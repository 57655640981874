import { Layout } from 'components/shared/Layout'
import { Tile } from '../shared/Tile'
import { useQuery } from 'react-query'
import { ExtrasInfo, Vouchers } from 'types/types'
import { fetchExtras } from 'utils/requests'
import { useFormikContext } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { SkeletonTiles } from 'components/shared/SkeletonTiles'
import { useToast } from '@chakra-ui/react'
import { Toast } from 'components/shared/Toast'
import { motion } from 'framer-motion'
import { bestSellerItemIds, listVariants } from 'utils/utils'
import clsx from 'clsx'
import cross from 'assets/images/cross.svg'
import { FormNavigationContext } from 'context/ReservationFormNavigationContext'

export const ReservationExtras = () => {
  const {
    goToNextStep,
    currentStepIndex,
    previousStepIndex,
    goToPreviousStep,
  } = useContext(FormNavigationContext)

  const toast = useToast()
  const { values } = useFormikContext<Vouchers>()

  const canAddExtras = !!values.vouchers?.some(
    voucher => !voucher.extras || Object.keys(voucher.extras).length === 0,
  )

  const {
    data: extras,
    isLoading,
    isError,
  } = useQuery<ExtrasInfo[]>('extras', fetchExtras)

  const bestSellerExtraIds = bestSellerItemIds.extras

  if (isError && !toast.isActive('extras-error'))
    toast({
      id: 'extras-error',
      position: 'top',
      status: 'error',
      duration: 4000,
      isClosable: true,
      render: () => (
        <Toast
          status={'error'}
          title={'Nepodařilo se načíst produkty. Zkuste prosím později.'}
        />
      ),
    })

  const preSelectedExtrasQuantity = values.vouchers.reduce((acc, voucher) => {
    return acc + ((voucher.extras as any)?.price === 0 ? 1 : 0)
  }, 0)

  useEffect(() => {
    if (!canAddExtras) {
      if (previousStepIndex < currentStepIndex) goToNextStep()
      if (previousStepIndex > currentStepIndex) goToPreviousStep()
    }
  }, [])

  return (
    <Layout stepName='Videozáznam' title='Dáme k tomu ještě vzpomínku?'>
      <p className='text-white mt-20 lg:mt-38 text-14 lg:text-16 text-center mx-20 sm:mx-44 md:mx-80 lg:mx-100 xl:mx-0'>
        81% zákazníků si vyzkouší létání pouze jednou. Proto Vám doporučujeme si
        nechat natočit emoce z prvních pokusů z bezprostřední blízkosti vodního
        skútru. Takové záběry bohužel ze břehu natočit nejdou. Nebudete litovat!
      </p>

      {isLoading ? (
        <SkeletonTiles number={3} />
      ) : (
        <motion.div
          variants={listVariants}
          className='mt-60 mb-124 flex flex-wrap gap-28 justify-center mx-20 sm:mx-44 md:mx-80 lg:mx-100 xl:mx-0'
          initial='initial'
          animate='animate'
        >
          {extras?.map((extra, index) => {
            if (extra.price === 0 && preSelectedExtrasQuantity === 0)
              return null
            return (
              <ExtrasItem
                key={index}
                extra={extra}
                isBestseller={bestSellerExtraIds.includes(extra.id)}
              />
            )
          })}
        </motion.div>
      )}
    </Layout>
  )
}
interface ExtrasItemProps {
  extra: ExtrasInfo
  isBestseller?: boolean
}

const ExtrasItem = ({ extra, isBestseller }: ExtrasItemProps) => {
  const [isSelected, setIsSelected] = useState(false)
  const [showSelector, setShowSelector] = useState(false)
  const { values, setValues } = useFormikContext<Vouchers>()
  useEffect(() => {
    if (values.vouchers.length !== 1) return
    const isSelected = values.vouchers.some(
      voucher => voucher.extras?.id === extra.id,
    )

    setIsSelected(isSelected)
  }, [])

  return (
    <div className='flex flex-col'>
      <Tile
        badgeText={isBestseller ? 'Bestseller' : undefined}
        key={extra.id}
        title={extra.name}
        subtitle={`${extra.price},-`}
        isSelected={isSelected}
        onClick={() => {
          if (values.vouchers.length === 1) {
            if (
              values.vouchers[0].extras &&
              (values.vouchers[0] as any).price === 0
            )
              return

            if (!isSelected) {
              setIsSelected(true)
              setValues(storedValues => {
                const newExtras = {
                  id: extra.id,
                  discountedPrice: extra.price,
                  quantity: 1,
                  name: extra.name,
                  voucher_id: values.vouchers[0].id,
                }
                const voucherWithExtra = {
                  ...values.vouchers[0],
                  extras: newExtras,
                }

                return {
                  ...storedValues,
                  vouchers: [voucherWithExtra],
                  order_data: {
                    order_type: 'reservation',
                    extras: [
                      ...(storedValues?.order_data?.extras ?? []),
                      newExtras,
                    ],
                  },
                }
              })
            } else {
              setIsSelected(false)
              setValues(storedValues => {
                const { order_data, ...values } = storedValues
                const { extras, ...rest } = values.vouchers[0]

                return {
                  ...values,
                  vouchers: [rest],
                }
              })
            }
          } else {
            setShowSelector(!showSelector)
          }
        }}
        selector={
          <VoucherSelectorOverlay extra={extra} showSelector={showSelector} />
        }
        closeSelector={() => setShowSelector(false)}
      />
    </div>
  )
}

interface VoucherSelectorOverlayProps {
  extra: ExtrasInfo
  showSelector: boolean
}

const VoucherSelectorOverlay = ({
  extra,
  showSelector,
}: VoucherSelectorOverlayProps) => {
  const { values, setValues } = useFormikContext<Vouchers>()
  const isExtrasDisabled = extra.price === 0
  if (values.vouchers.length === 1) return null
  return (
    <div className='absolute top-[100%] z-50 flex flex-col gap-2 mt-1'>
      {/* Selected vouchers */}
      {values.vouchers.map((voucher, index) => {
        const { voucher_code: voucherCode, extras } = voucher

        if (!extras || extras?.id !== extra.id) return null

        return (
          <div
            className={clsx(
              'lg:w-168 w-114 h-34 rounded text-base font-title cursor-pointer text-center text-22 flex border  justify-center items-center relative ',
              isExtrasDisabled || extras.is_order_paid === true
                ? 'text-white bg-gray border-darkerGray'
                : 'text-black bg-yellow border-yellow',
            )}
            key={`${voucherCode}-selected`}
          >
            <span>{voucherCode}</span>

            {!isExtrasDisabled ||
              (!extras.is_order_paid && extra.id === extras?.id && (
                <img
                  src={cross}
                  alt='Cross icon'
                  className='absolute top-50% right-10'
                  onClick={e => {
                    e.stopPropagation()
                    setValues(storedValues => {
                      const { extras, ...rest } = voucher
                      const updatedVouchers = [...values.vouchers]
                      updatedVouchers[index] = rest

                      const updatedOrderDataExtras =
                        storedValues.order_data?.extras.filter(extra => {
                          return extra.voucher_id !== extras?.voucher_id
                        }) ?? []

                      return {
                        ...storedValues,
                        vouchers: updatedVouchers,
                        order_data: {
                          order_type: 'reservation',
                          extras: updatedOrderDataExtras,
                        },
                      }
                    })
                  }}
                />
              ))}
          </div>
        )
      })}
      {/* Not selected vouchers */}
      {showSelector &&
        values.vouchers.map((voucher, index) => {
          const { voucher_code: voucherCode, extras } = voucher

          if (extras && Object.keys(extras).length > 0) return null

          return (
            <div
              className={clsx(
                'lg:w-168 w-114 h-34 rounded text-base font-title cursor-pointer text-center text-22 flex border border-yellow justify-center items-center relative text-white bg-black',
              )}
              key={`${voucherCode}-notSelected`}
              onClick={() => {
                if (isExtrasDisabled) return

                const newExtras = {
                  id: extra.id,
                  discountedPrice: extra.price,
                  quantity: 1,
                  name: extra.name,
                  voucher_id: voucher.id,
                }

                setValues(storedValues => {
                  const voucherWithExtra = {
                    ...voucher,
                    extras: newExtras,
                  }
                  const updatedVouchers = [...values.vouchers]
                  updatedVouchers[index] = voucherWithExtra

                  return {
                    ...storedValues,
                    vouchers: updatedVouchers,
                    order_data: {
                      order_type: 'reservation',
                      extras: [
                        ...(storedValues?.order_data?.extras ?? []),
                        newExtras,
                      ],
                    },
                  }
                })
              }}
            >
              <span>{voucherCode}</span>
            </div>
          )
        })}
    </div>
  )
}
