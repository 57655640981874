import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { NewOrderForm } from './NewOrderForm'
import { orderValidationSchema } from 'utils/validation-schemas'
import { format } from 'date-fns'

interface CreateOrderModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateOrderModal = ({
  isOpen,
  onClose,
}: CreateOrderModalProps) => {
    const currentDateTime = format(new Date(), "yyyy-MM-dd'T'HH:mm");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
      <ModalOverlay sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }} />
      <ModalContent color={'white'} border={'1px solid white'} borderRadius={0}>
        <ModalCloseButton color={'white'} />
        <ModalHeader
          className='font-title text-36 text-center'
          fontWeight={'normal'}
        >
          Nová objednávka
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              merchTotal: 0,
              lessonTotal: 0,
              extrasTotal: 0,
              purchaseType: {
                lekce: true,
                video: true,
                merch: true,
              },
              payment: {
                payment_type: 'cash',
              },
                lesson: {
                datetime: currentDateTime,
              }
            }}
            onSubmit={() => {}}
            validationSchema={orderValidationSchema}
          >
            {() => {
              return <NewOrderForm onClose={onClose} />
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
