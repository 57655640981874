import { AdminButton } from 'components/shared/AdminButton'
import { Reservation, ReservationState, UpdatedReservation } from 'types/types'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { Formik, Form, Field, useFormikContext } from 'formik'
import { updateReservation } from 'utils/requests'
import { Toast } from 'components/shared/Toast'
import { InfoOverlay } from 'components/shared/InfoOverlay'

import { InputError } from 'components/shared/InputError'
import { adminReservationSchema } from 'utils/validation-schemas'
import { useEffect } from 'react'

interface ExpandableReservationRowProps {
  reservation: Reservation
  toggleExpanded: () => void
  expandedIndex: number
}

export const ExpandableReservationRow = ({
  reservation,
  toggleExpanded,
  expandedIndex,
}: ExpandableReservationRowProps) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { used_by, message, status } = reservation

  const { mutate: updateReservationData, isLoading } = useMutation(
    updateReservation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('reservations')
        toggleExpanded()
        toast({
          position: 'top',
          status: 'success',
          duration: 4000,
          isClosable: true,
          render: () => (
            <Toast status={'success'} title={'Změny byly úspěšně uloženy.'} />
          ),
        })
      },
      onError: (error: any) => {
        toast({
          position: 'top',
          status: 'error',
          duration: 4000,
          isClosable: true,
          render: () => (
            <Toast
              status={'error'}
              title={
                error.message ??
                'Něco se nepovedlo - kontaktujte správce systému'
              }
            />
          ),
        })
      },
    },
  )

  const updateVoucherData = async (values: UpdatedReservation) => {
    // TODO check lower case value if changed
    const updatedReservationData = {
      customer_data: {
        id: used_by?.id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_number: values.phone_number,
        instagram: values.instagram,
      },
      voucher_data: {
        id: reservation.id,
        message: values.message,
        status: values.status,
      },
    }

    updateReservationData(updatedReservationData)
  }

  const initialValues = {
    first_name: used_by?.first_name,
    last_name: used_by?.last_name,
    email: used_by?.email,
    phone_number: used_by?.phone_number,
    instagram: used_by?.instagram,
    message: message,
    status,
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={adminReservationSchema}
    >
      {() => {
        return (
          <UpdateReservationForm
            reservation={reservation}
            toggleExpanded={toggleExpanded}
            updateVoucherData={updateVoucherData}
            isLoading={isLoading}
            expandedIndex={expandedIndex}
            initialValues={initialValues}
          />
        )
      }}
    </Formik>
  )
}

interface UpdateReservationFormProps {
  reservation: Reservation
  toggleExpanded: () => void
  updateVoucherData: (values: UpdatedReservation) => void
  isLoading: boolean
  expandedIndex: number
  initialValues: UpdatedReservation
}

const UpdateReservationForm = ({
  reservation,
  toggleExpanded,
  updateVoucherData,
  isLoading,
  expandedIndex,
  initialValues,
}: UpdateReservationFormProps) => {
  const { errors, values, touched, resetForm } =
    useFormikContext<UpdatedReservation>()

  const { order_merch, vouchers_count_on_order, extras } = reservation

  useEffect(() => {
    resetForm({ values: initialValues })
  }, [expandedIndex, initialValues, resetForm])

  return (
    <Form>
      <div className='flex flex-col gap-12 lg:gap-30 lg:pt-22 lg:px-10 lg:pb-10'>
        <div className='flex'>
          <p className='font-title text-yellow w-120 text-14 lg:text-16'>
            Zákazník
          </p>
          <div className='flex gap-30'>
            <div className='flex flex-col gap-4'>
              <label className='font-title text-yellow my-auto text-14 lg:text-16 whitespace-nowrap'>
                Jméno *
              </label>
              <Field
                type='text'
                name='first_name'
                className='w-full h-44 px-10 max-w-169 border bg-darkGray focus:outline-none focus:border-white text-white block border-yellow rounded text-14 lg:text-16 font-title'
              />
              {errors.first_name && touched.first_name && (
                <InputError errorText={errors.first_name || ''} />
              )}
            </div>
            <div className='flex flex-col gap-4'>
              <label className='font-title text-yellow my-auto text-14 lg:text-16 whitespace-nowrap'>
                Příjmení *
              </label>
              <Field
                type='text'
                name='last_name'
                className='w-full h-44 px-10 max-w-169 border bg-darkGray focus:outline-none focus:border-white text-white block border-yellow rounded text-14 lg:text-16 font-title'
              />
              {errors.last_name && touched.last_name && (
                <InputError errorText={errors.last_name || ''} />
              )}
            </div>
            <div className='flex flex-col gap-4'>
              <label className='font-title text-yellow my-auto text-14 lg:text-16 whitespace-nowrap'>
                Telefon
              </label>
              <Field
                type='text'
                name='phone_number'
                className='w-full h-44 px-10 max-w-169 border bg-darkGray focus:outline-none focus:border-white text-white block border-yellow rounded text-14 lg:text-16 font-title'
              />
              {errors.phone_number && touched.phone_number && (
                <InputError errorText={errors.phone_number || ''} />
              )}
            </div>
            <div className='flex flex-col gap-4'>
              <label className='font-title text-yellow my-auto text-14 lg:text-16 whitespace-nowrap'>
                Email
              </label>
              <Field
                type='email'
                name='email'
                className='w-full h-44 px-10 max-w-169 border bg-darkGray focus:outline-none focus:border-white text-white block border-yellow rounded text-14 lg:text-16 font-title'
              />
              {errors.email && touched.email && (
                <InputError errorText={errors.email || ''} />
              )}
            </div>
            <div className='flex flex-col gap-4'>
              <label className='font-title text-yellow my-auto text-14 lg:text-16 whitespace-nowrap'>
                Instagram
              </label>
              <Field
                type='text'
                name='instagram'
                className='w-full h-44 px-10 max-w-169 border bg-darkGray focus:outline-none focus:border-white text-white block border-yellow rounded text-14 lg:text-16 font-title'
              />
            </div>
          </div>
        </div>
        <div className='flex gap-30'>
          {extras && Object.keys(extras).length > 0 && (
              <div className='flex'>
                <div className='flex w-120 gap-6 items-center'>
                  <p className='font-title text-yellow text-14 lg:text-16 shrink-0'>
                    Video
                  </p>
                  {!extras.is_order_paid && (
                      <InfoOverlay
                          fill='#FF0000'
                          label={
                            <p className='font-body text-12 lg:text-14 normal-case tracking-normal'>
                              Video nebylo zaplaceno
                            </p>
                          }
                      />
                  )}
                </div>
                <p className='flex items-center text-14 lg:text-16'>
                  {extras.name}
                </p>
              </div>
          )}
        </div>
        {order_merch && order_merch.length > 0 && (
            <div className='flex'>
              <div className='flex w-120 gap-6 items-center'>
                <p className='font-title text-yellow text-14 lg:text-16 shrink-0'>
                  Merch
                </p>
                {vouchers_count_on_order > 1 && (
                    <InfoOverlay
                        fill='#FF0000'
                        label={
                          <p className='font-body text-12 lg:text-14 normal-case tracking-normal'>
                            Objednávka má více voucherů, které mohou obsahovat merch
                          </p>
                        }
                    />
                )}
              </div>
              <p className='text-14 lg:text-16'>
              {order_merch
                .map(merch => `${merch.quantity} x ${merch.merch_name}`)
                .join(', ')}
            </p>
          </div>
        )}

        <div className='flex gap-60'>
          <div className='w-400'>
            <p className='font-title text-yellow text-14 lg:text-16 w-120 shrink-0'>
              Poznámka
            </p>
            <Field
              as='textarea'
              type='text'
              name='message'
              className='w-full min-h-100 px-10 border bg-darkGray focus:outline-none focus:border-white text-white block border-yellow rounded text-14 lg:text-16 font-title py-6 resize-none'
            />
          </div>
          <div className='flex flex-col gap-4'>
            <label className='font-title text-yellow my-auto text-14 lg:text-16 whitespace-nowrap'>
              Stav *
            </label>
            <Field
              as='select'
              name='status'
              className='w-full h-44 px-10 max-w-169 border bg-darkGray focus:outline-none focus:border-white text-white block border-yellow rounded text-14 lg:text-16 font-title'
            >
              <option value='' disabled>
                Vyber stav
              </option>
              {Object.keys(ReservationState).map(state => {
                const stateKey = state as keyof typeof ReservationState

                return (
                  <option key={state} value={state}>
                    {ReservationState[stateKey]}
                  </option>
                )
              })}
            </Field>
          </div>
        </div>

        <div className='flex gap-20 justify-end'>
          <AdminButton
            title='Zrušit'
            className='bg-black border-yellow text-yellow'
            onClick={() => {
              resetForm()
              toggleExpanded()
            }}
          />
          <AdminButton
            title='Uložit'
            className='bg-yellow border-yellow text-black'
            onClick={() => updateVoucherData(values)}
            disabled={Object.keys(errors).length > 0 || isLoading}
          />
        </div>
      </div>
    </Form>
  )
}
