import { useToast } from '@chakra-ui/react'
import { Response } from 'types/types'
import { Form, useFormikContext } from 'formik'
import { getOrderFormFields } from 'utils/utils'
import { AdminButton } from 'components/shared/AdminButton'
import { useMutation, useQueryClient } from 'react-query'
import { createAdminOrder } from 'utils/requests'
import { Toast } from 'components/shared/Toast'
import { AddLesson } from './AddLesson'
import { AddMerch } from './AddMerch'
import { AddExtras } from './AddExtras'
import { AddPayment } from './AddPayment'
import { AddCustomer } from './AddCustomer'
import { AddPurchaseTypes } from './AddPurchaseTypes'
import { AddAdditionalInfo } from './AddAdditionalInfo'
import { format, parseISO } from 'date-fns'

interface NewOrderFormProps {
  onClose: () => void
}

export const NewOrderForm = ({ onClose }: NewOrderFormProps) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { values, errors } = useFormikContext<any>()
  const orderFormFields = getOrderFormFields()

  const postNewAdminOrder = (values: any) => {
    const orderData: any = {
      action: 'create_order',
      order_data: {
        total_amount:
          values.payment?.total_amount ??
          values.lessonTotal + values.merchTotal + values.extrasTotal,
        payment_type: values.payment.payment_type,
        order_type: values.purchaseType.lekce ? 'voucher' : 'shop',
        message: values.info?.message ?? null,
        customer: {
          first_name: values.customer.first_name,
          last_name: values.customer.last_name,
          email: values.customer?.email,
          phone: values.customer?.phone_number ?? null,
          gdpr: true,
          know_from: 'On site',
          instagram: values.customer?.instagram ?? null,
        },
      },
    }

    if (values.lesson && values.purchaseType.lekce) {
      orderData.order_data.lessonType = [
        {
          code: Number(values.lesson.code),
        },
      ]
    }
    if (values.merch && values.purchaseType.merch) {
      orderData.order_data.merch = [
        {
          id: Number(values.merch.id),
          quantity: 1,
          discountedPrice: values.merchTotal,
        },
      ]
      if (values.merch?.size)
        orderData.order_data.merch[0].size = values.merch.size
    }

    // Pridat validation na to ze branch_id a datum a cas musi byt posilany spolu
    if (values.extras && values.purchaseType.video) {
      orderData.order_data.extras = [
        {
          id: Number(values.extras.id),
          quantity: 1,
          discountedPrice: values.extrasTotal,
        },
      ]
    }

    if (values.lesson?.branch_id || values.lesson?.datetime) {
      orderData.reservation_data = {}
      if (values.lesson?.branch_id)
        orderData.reservation_data.branch_id = Number(values.lesson.branch_id)
      if (values.lesson?.datetime) {
        const dateObject = parseISO(values.lesson.datetime)
        const date = format(dateObject, 'yyyy-MM-dd')
        const time = format(dateObject, 'HH:mm')
        orderData.reservation_data.date = date
        orderData.reservation_data.time = time
      }
    }

    createNewOrder(orderData)
  }

  const { mutate: createNewOrder, isLoading } = useMutation(createAdminOrder, {
    onSuccess: data => {
      queryClient.invalidateQueries('reservations')
      const status = data.code === 200 ? 'success' : 'error'
      toast({
        position: 'top',
        status,
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast
            status={status}
            title={
              status === 'success'
                ? 'Objednávka byla úspěšně vytvořena'
                : 'Něco se pokazilo'
            }
          />
        ),
      })
      onClose()
    },
    onError: (data: Response) => {
      toast({
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast
            status={'error'}
            title={
              data.message_headline ||
              'Něco se pokazilo. Kontaktujte nás prosím.'
            }
          />
        ),
      })
    },
  })

  return (
    <>
      <Form>
        <AddCustomer customerFields={orderFormFields.customer} />
        <AddPurchaseTypes />
        <div className='flex justify-between'>
          {values.purchaseType.lekce && orderFormFields.lesson && (
            <AddLesson lessonFields={orderFormFields.lesson} />
          )}
          <div className='w-[42%]'>
            {values.purchaseType.video && orderFormFields.extras && (
              <AddExtras extrasFields={orderFormFields.extras} />
            )}
            {values.purchaseType.merch && orderFormFields.merch && (
              <AddMerch merchFields={orderFormFields.merch} />
            )}
          </div>
        </div>
        <AddPayment paymentFields={orderFormFields.payment} />
        <AddAdditionalInfo />
      </Form>
      <div className='flex gap-20 justify-between mb-16 mt-20'>
        <AdminButton
          title='Zrušit'
          className='bg-black border-yellow text-yellow'
          onClick={onClose}
        />
        <AdminButton
          title='Uložit'
          className='bg-yellow border-yellow text-black'
          disabled={
            Object.keys(values).length < 5 ||
            Object.keys(errors).length > 0 ||
            isLoading
          }
          onClick={() => postNewAdminOrder(values as any)}
        />
      </div>
    </>
  )
}
