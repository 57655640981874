import { useSessionStorage } from 'hooks/useSessionStorage'
import { createContext, useEffect, useContext, useCallback } from 'react'
import axiosClient from 'utils/axios-instance'
import { getApiKey } from 'utils/requests'

const APIAccessContext = createContext({
  apiKey: null,
  refreshApiKey: () => {},
})

export const useApiAccess = () => useContext(APIAccessContext)

interface ApiAccessProviderProps {
  children: React.ReactNode
}
export const ApiAccessProvider = ({ children }: ApiAccessProviderProps) => {
  const [apiKey, setApiKey] = useSessionStorage('apiKey', null)

  const refreshApiKey = useCallback(async () => {
    try {
      const response = await getApiKey()
      if (response.api_key) {
        setApiKey(response.api_key)
        return response.api_key
      }
    } catch (error) {
      console.error('Failed to refresh API key:', error)
    }
    return null
  }, [setApiKey])

  useEffect(() => {
    const requestInterceptor = axiosClient.interceptors.request.use(config => {
      if (apiKey) {
        config.headers['X-API-Key'] = apiKey
      }
      return config
    })

    const responseInterceptor = axiosClient.interceptors.response.use(
      response => response,
      async error => {
        if (error && error.response.status === 401) {
          const newApiKey = await refreshApiKey()
          if (newApiKey) {
            error.config.headers['X-API-Key'] = newApiKey
            return axiosClient(error.config)
          }
        }
        return Promise.reject(error)
      },
    )

    return () => {
      axiosClient.interceptors.request.eject(requestInterceptor)
      axiosClient.interceptors.response.eject(responseInterceptor)
    }
  }, [apiKey, refreshApiKey])

  useEffect(() => {
    if (!apiKey) {
      refreshApiKey()
    }
  }, [apiKey, refreshApiKey])

  const value = { apiKey, refreshApiKey }

  return (
    <APIAccessContext.Provider value={value}>
      {children}
    </APIAccessContext.Provider>
  )
}
