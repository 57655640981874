import { useFormikContext } from 'formik'
import { Order, Vouchers } from 'types/types'
import { Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import { createCheckoutSession } from 'utils/requests'
import { useMutation } from 'react-query'

import { usePriceTotals } from 'hooks/usePriceTotals'
import { BasketTableRow } from '../voucher-purchase-form/BasketTableRow'
import { formatPrice, groupExtrasAndCount } from 'utils/utils'
import { Toast } from 'components/shared/Toast'
import { useToast } from '@chakra-ui/react'
import { usePrompt } from 'hooks/usePrompt'

import stripe from 'assets/images/stripe.svg'
import { FormNavigationContext } from 'context/ReservationFormNavigationContext'

const apiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = apiKey && loadStripe(apiKey)

export const ExtrasPayment = () => {
  const toast = useToast()
  usePrompt()

  const { values } = useFormikContext<Vouchers>()

  const { goToNextStep } = useContext(FormNavigationContext)

  const [clientSecret, setClientSecret] = useState<string>('')

  const totalPrice = values.order_data?.extras.reduce((acc, extra) => {
    return acc + extra.discountedPrice
  }, 0)

  const { mutate: createSession } = useMutation(createCheckoutSession, {
    onSuccess: data => {
      setClientSecret(data)
    },
    onError: () => {
      toast({
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast
            status={'error'}
            title={'Něco se pokazilo. Kontaktujte nás prosím.'}
          />
        ),
      })
    },
  })

  useEffect(() => {
    if (
      values.orderId &&
      values.order_data &&
      values.order_data.extras &&
      values.order_data.extras.length > 0
    ) {
      createSession({
        orderId: values.orderId,
        type: 'reservation',
      })
    } else {
      goToNextStep()
    }
  }, [createSession, values.orderId])

  return (
    <div className='w-screen h-fit flex flex-col-reverse lg:flex-row overflow-auto'>
      <div className='bg-black lg:w-1/2 py-60 lg:px-60 flex flex-col'>
        <h4 className='text-yellow font-title text-16 text-center lg:text-left'>
          Celková částka
        </h4>
        {totalPrice && (
          <p className='text-yellow font-title text-50 lg:text-heading lg:mb-30 text-center lg:text-left'>
            {formatPrice(totalPrice)},- CZK
          </p>
        )}

        <TableContainer
          className='grow'
          display={{
            base: 'none',
            lg: 'block',
          }}
        >
          <Table>
            <Tbody className='text-white font-title'>
              {values.order_data?.extras.map(extra => (
                <BasketTableRow
                  key={extra.id}
                  count={extra.quantity}
                  item={extra as any}
                  type='extras'
                  isHover={false}
                />
              ))}

              <Tr>
                <Td border={'none'} borderTop={'1px solid white'}></Td>

                <Td
                  border={'none'}
                  borderTop={'1px solid white'}
                  padding={'5px'}
                  className='text-white'
                >
                  CELKEM
                </Td>

                <Td
                  border={'none'}
                  borderTop={'1px solid white'}
                  padding={'5px'}
                >
                  {totalPrice && formatPrice(totalPrice)},-
                </Td>
                <Td
                  border={'none'}
                  borderTop={'1px solid white'}
                  padding={'5px'}
                >
                  CZK
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <div className='text-yellow text-12 font-body lg:flex mt-30 justify-center hidden'>
          <div className='flex gap-5 items-center border-r border-yellow pr-40'>
            <span>Powered by </span> <img src={stripe} alt='Stripe logo' />
          </div>
          <div className='border-l border-yellow pl-40 flex gap-22'>
            <a
              href='https://stripe.com/en-cz/legal/consumer'
              target='_blank'
              rel='noreferrer'
            >
              Terms
            </a>

            <a
              href='https://stripe.com/en-cz/privacy'
              target='_blank'
              rel='noreferrer'
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
      <div className='lg:w-1/2 min-h-screen flex items-center bg-white'>
        <div id='checkout' className='w-full'>
          {clientSecret && stripePromise && (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
        </div>
      </div>
    </div>
  )
}
