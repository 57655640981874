import { AdminTable } from 'components/shared/AdminTable'
import { useQuery } from 'react-query'
import { Branch, Reservation } from 'types/types'
import { fetchBranches, fetchReservations } from 'utils/requests'
import { getReservationsTableColumns } from 'utils/table-config'
import { ExpandableReservationRow } from './ExpandableReservationRow'
import { useMemo, useState } from 'react'
import { TabList } from 'components/shared/TabList'
import { AdminDayPicker } from 'components/shared/AdminDayPicker'
import { compareAsc, isSameDay, parseISO } from 'date-fns'
import { SmallAddIcon } from '@chakra-ui/icons'
import { useDisclosure } from '@chakra-ui/react'
import { CreateOrderModal } from './CreateOrderModal'

export const AdminReservations = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: reservations } = useQuery<Reservation[]>(
    'reservations',
    fetchReservations,
  )
  const { data: branches } = useQuery<Branch[]>('branches', fetchBranches)

  const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null)
  const [selectedDay, setSelectedDay] = useState<Date | undefined>()

  const branchTabListItems = useMemo(
    () => branches?.map(branch => ({ id: branch.id, name: branch.name })),
    [branches],
  )

  const reservationsByBranch = selectedBranchId
    ? reservations?.filter(
        reservation => reservation.reservation.branch_id === selectedBranchId,
      )
    : reservations

  const resevationDays = Array.from(
    new Set(
      reservationsByBranch?.map(
        reservation => new Date(reservation.reservation.date),
      ),
    ),
  )

  const columns = getReservationsTableColumns()

  const filteredReservations = useMemo(() => {
    let filteredReservations = reservations
    if (selectedDay) {
      filteredReservations = reservations?.filter(reservation => {
        return isSameDay(new Date(reservation.reservation.date), selectedDay)
      })
    }
    if (selectedBranchId) {
      filteredReservations = filteredReservations?.filter(
        reservation => reservation.reservation.branch_id === selectedBranchId,
      )
    }

    return filteredReservations?.sort((a, b) => {
      return compareAsc(
        parseISO(`${a.reservation.date}T${a.reservation.time}`),
        parseISO(`${b.reservation.date}T${b.reservation.time}`),
      )
    })
  }, [reservations, selectedDay, selectedBranchId])

  const filteredBranchSet = new Set(
    filteredReservations?.map(reservation => reservation.reservation.branch_id),
  )

  const filteredBranchTabListItems = selectedDay
    ? branchTabListItems?.filter(branch => filteredBranchSet.has(branch.id))
    : branchTabListItems

  return (
    <div className='w-full'>
      <div className='flex justify-between mb-20 lg:mb-0'>
        <h1 className='text-white font-title text-26 lg:text-subtitle'>
          Rezervace
        </h1>
        <button
          className='h-40 font-title text-yellow border border-yellow text-12 lg:text-14 lg:py-8 px-6 lg:px-16 flex gap-10 items-center lg:w-200'
          onClick={onOpen}
        >
          <SmallAddIcon
            boxSize={{
              base: 4,
              lg: 6,
            }}
          />
          Vytvořit objednávku
        </button>
      </div>
      <CreateOrderModal isOpen={isOpen} onClose={onClose} />

      {filteredBranchTabListItems && filteredBranchTabListItems.length > 0 && (
        <TabList
          items={filteredBranchTabListItems}
          selectedTabId={selectedBranchId}
          setSelectedId={setSelectedBranchId}
          className={'justify-center'}
        />
      )}
      <AdminDayPicker
        key={selectedBranchId}
        availabileDays={resevationDays}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />
      {filteredReservations && (
        <AdminTable
          data={filteredReservations}
          columns={columns}
          isPaginated={true}
          isFilterable={true}
          isExpandable={true}
          expandedRowRender={(
            reservation: Reservation,
            toggleExpanded: () => void,
            expandedIndex: number,
          ) => (
            <ExpandableReservationRow
              key={`${reservation.id}-${reservation.code}`}
              reservation={reservation}
              toggleExpanded={toggleExpanded}
              expandedIndex={expandedIndex}
            />
          )}
          pagesPerView={100}
        />
      )}
    </div>
  )
}
