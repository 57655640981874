import { DayPicker } from 'react-day-picker'
import { cs } from 'date-fns/locale'
import 'react-day-picker/dist/style.css'
import 'styles/admin-day-picker.css'
import styles from 'styles/day-picker.module.css'
import { getFirstAvailableMonth } from 'utils/utils'

interface AdminAvailabilityProps {
  availabileDays: Date[]
  selectedDay: Date | undefined
  setSelectedDay: (date: Date | undefined) => void
}

export const AdminDayPicker = ({
  availabileDays,
  selectedDay,
  setSelectedDay,
}: AdminAvailabilityProps) => {
    const currentMonth = new Date();
    // const firstAvailableMonth = getFirstAvailableMonth(availabileDays);
;
  return (
    <>
      {availabileDays.length > 0 && (
        <DayPicker
          mode='single'
          className={'mt-20 text-white mx-auto'}
          selected={selectedDay}
          locale={cs}
          defaultMonth={currentMonth}
          modifiers={{
            available: availabileDays,
          }}
          modifiersClassNames={{
            available: styles.available,
            disabled: styles.disabled,
            selected: styles.selected,
          }}
          onSelect={date => setSelectedDay(date)}
        />
      )}
      {availabileDays.length === 0 && (
        <DayPicker
          mode='single'
          className={'mt-20 text-white mx-auto'}
          defaultMonth={new Date()}
          locale={cs}
          disabled={true}
        />
      )}
    </>
  )
}
