import { useToast } from '@chakra-ui/react'
import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from 'react'
import { loginUser, registerUser, validateToken } from 'utils/requests'
import { Toast } from 'components/shared/Toast'
import { AuthenticatedUser, NewUser } from 'types/types'
import { useLocalStorage } from 'hooks/useLocalStorage'

type AuthContextType = {
  currentUser: AuthenticatedUser | null
  login: ({
    username,
    password,
  }: {
    username: string
    password: string
  }) => Promise<void>
  logout: () => void
  signUp: (user: NewUser) => Promise<void>
}

type AuthProviderProps = {
  children: ReactNode
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const toast = useToast()

  const [currentUser, setCurrentUser] = useLocalStorage('fbsUser', null)

  const login = async ({
    username,
    password,
  }: {
    username: string
    password: string
  }): Promise<void> => {
    try {
      const response = await loginUser({ username, password })

      if (response.code === 200) {
        const fbsUser = {
          username: response.username,
          access_token: response.access_token,
        }
        setCurrentUser(fbsUser)
      } else {
        toast({
          position: 'top',
          status: 'error',
          duration: 4000,
          isClosable: true,
          render: () => (
            <Toast
              status={'error'}
              title={
                response.message || 'Něco se pokazilo. Kontaktujte nás prosím.'
              }
            />
          ),
        })
      }
    } catch (error: any) {
      toast({
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast
            status={'error'}
            title={error.message || 'Něco se pokazilo. Kontaktujte nás prosím.'}
          />
        ),
      })
    }
  }

  const logout = useCallback(() => {
    setCurrentUser(null)
    window.location.href = '/login'
  }, [setCurrentUser])

  const signUp = async (user: NewUser): Promise<void> => {
    try {
      const response = await registerUser(user)
      if (response.code === 201) {
        toast({
          position: 'top',
          status: 'success',
          duration: 4000,
          isClosable: true,
          render: () => (
            <Toast
              status={'success'}
              title={
                'Uživatel vytvořen, prosím kontaktujte admina pro dokončení registrace'
              }
            />
          ),
        })
      } else {
        toast({
          position: 'top',
          status: 'error',
          duration: 4000,
          isClosable: true,
          render: () => (
            <Toast
              status={'error'}
              title={
                response.message || 'Něco se pokazilo. Kontaktujte nás prosím.'
              }
            />
          ),
        })
      }
    } catch (error: any) {
      toast({
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast
            status={'error'}
            title={error.message || 'Něco se pokazilo. Kontaktujte nás prosím.'}
          />
        ),
      })
    }
  }

  const value = {
    currentUser,
    login,
    logout,
    signUp,
  }

  useEffect(() => {
    const validateUserToken = async () => {
      if (currentUser && currentUser.access_token) {
        try {
          const response = await validateToken(
            currentUser.username,
            currentUser.access_token,
          )
          if (response.code !== 200) {
            logout()
          }
        } catch (error) {
          console.error('Token validation error:', error)
          logout()
        }
      }
    }

    window.location.pathname.startsWith('/admin') && validateUserToken()
  }, [currentUser, logout])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
