import { Form, Formik } from 'formik'
import { useContext } from 'react'
import {
  FormNavigationContext,
  FormNavigationProvider,
} from 'context/ReservationFormNavigationContext'
import { AnimatePresence } from 'framer-motion'
import clsx from 'clsx'

import { reservationSchema } from 'utils/validation-schemas'

const initialValues = {}

export const LessonReservationForm = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={reservationSchema}
      onSubmit={values => {}}
    >
      {() => {
        return (
          <FormNavigationProvider>
            <LessonReservation />
          </FormNavigationProvider>
        )
      }}
    </Formik>
  )
}

export const LessonReservation = () => {
  const { currentStepIndex, formSteps } = useContext(FormNavigationContext)

  return (
    <Form>
      <AnimatePresence>
        <div className={clsx(currentStepIndex === 7 ? 'bg-white' : 'bg-black')}>
          {formSteps[currentStepIndex]}
        </div>
      </AnimatePresence>
    </Form>
  )
}
