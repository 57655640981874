import { useVoucherStats } from 'hooks/useVoucherStats'
import { ModifiedAdminVoucher } from 'types/types'
import { voucherStatsConfig } from 'utils/stats-config'

interface VoucherStatsProps {
  vouchers: ModifiedAdminVoucher[]
}

export const VoucherStats = ({ vouchers }: VoucherStatsProps) => {
  const voucherStats = useVoucherStats(vouchers)

  return (
    <div className='flex flex-wrap justify-between gap-10 lg:gap-y-32 lg:gap-x-60'>
      {voucherStatsConfig.map(voucher => {
        const { id, title, key } = voucher
        return (
          <div
            key={id}
            className='px-10 lg:p-24 w-140 lg:w-300 lg:h-127 rounded-lg bg-darkGray border border-yellow font-title flex items-center justify-between lg:block'
          >
            <p className='text-white text-14 lg:text-20'>{title}</p>
            <p className='text-yellow text-22 lg:text-37'>
              {voucherStats[key]}
            </p>
          </div>
        )
      })}
    </div>
  )
}
