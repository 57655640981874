import { Layout } from 'components/shared/Layout'
import { Basket } from '../voucher-purchase-form/Basket'
import { Tile } from '../shared/Tile'
import { useQuery } from 'react-query'
import { ExtrasInfo, Order } from 'types/types'
import { fetchExtras } from 'utils/requests'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { SkeletonTiles } from 'components/shared/SkeletonTiles'
import { useToast } from '@chakra-ui/react'
import { Toast } from 'components/shared/Toast'
import { motion } from 'framer-motion'
import { bestSellerItemIds, listVariants } from 'utils/utils'
import clsx from 'clsx'
import cross from 'assets/images/cross.svg'

export const Extras = () => {
  const toast = useToast()
  const { values } = useFormikContext<Order>()

  const {
    data: extras,
    isLoading,
    isError,
  } = useQuery<ExtrasInfo[]>('extras', fetchExtras)

  const bestSellerExtraIds = bestSellerItemIds.extras

  if (isError && !toast.isActive('extras-error'))
    toast({
      id: 'extras-error',
      position: 'top',
      status: 'error',
      duration: 4000,
      isClosable: true,
      render: () => (
        <Toast
          status={'error'}
          title={'Nepodařilo se načíst produkty. Zkuste prosím později.'}
        />
      ),
    })

  const preSelectedExtrasQuantity = values.lessonType.reduce((acc, lesson) => {
    return acc + ((lesson.extras as any)?.price === 0 ? 1 : 0)
  }, 0)

  return (
    <Layout
      stepName='Videozáznam'
      title='Vzpomínka na celý život'
      rightPart={<Basket />}
    >
      <p className='text-white mt-20 lg:mt-38 text-14 lg:text-16 text-center mx-20 sm:mx-44 md:mx-80 lg:mx-100 xl:mx-0'>
        81% zákazníků si vyzkouší létání pouze jednou. Proto Vám doporučujeme si
        nechat natočit emoce z prvních pokusů z bezprostřední blízkosti vodního
        skútru. Takové záběry bohužel ze břehu natočit nejdou. Nebudete litovat!
      </p>

      {isLoading ? (
        <SkeletonTiles number={3} />
      ) : (
        <motion.div
          variants={listVariants}
          className='mt-60 mb-124 flex flex-wrap gap-28 justify-center mx-20 sm:mx-44 md:mx-80 lg:mx-100 xl:mx-0'
          initial='initial'
          animate='animate'
        >
          {extras?.map((extra, index) => {
            if (extra.price === 0 && preSelectedExtrasQuantity === 0)
              return null
            return (
              <ExtrasItem
                key={index}
                extra={extra}
                isBestseller={bestSellerExtraIds.includes(extra.id)}
              />
            )
          })}
        </motion.div>
      )}
    </Layout>
  )
}
interface ExtrasItemProps {
  extra: ExtrasInfo
  isBestseller?: boolean
}

const ExtrasItem = ({ extra, isBestseller }: ExtrasItemProps) => {
  const [isSelected, setIsSelected] = useState(false)
  const [showSelector, setShowSelector] = useState(false)
  const { values, setValues } = useFormikContext<Order>()

  useEffect(() => {
    if (values.lessonType.length !== 1) return
    const isSelected = values.lessonType.some(
      lesson => lesson.extras?.id === extra.id,
    )

    setIsSelected(isSelected)
  }, [])

  return (
    <div className='flex flex-col'>
      <Tile
        badgeText={isBestseller ? 'Bestseller' : undefined}
        key={extra.id}
        title={extra.name}
        subtitle={`${extra.price},-`}
        isSelected={isSelected}
        onClick={() => {
          if (values.lessonType.length === 1) {
            if (
              values.lessonType[0].extras &&
              (values.lessonType[0].extras as any).price === 0
            )
              return
            if (!isSelected) {
              setIsSelected(true)
              setValues(storedValues => {
                const lessonWithExtra = {
                  ...values.lessonType[0],
                  extras: {
                    id: extra.id,
                    discountedPrice: extra.price,
                    quantity: 1,
                    name: extra.name,
                  },
                }

                return {
                  ...storedValues,
                  lessonType: [lessonWithExtra],
                }
              })
            } else {
              setIsSelected(false)
              setValues(storedValues => {
                const { extras, ...rest } = values.lessonType[0]

                return {
                  ...storedValues,
                  lessonType: [rest],
                }
              })
            }
          } else {
            setShowSelector(!showSelector)
          }
        }}
        selector={
          <VoucherSelectorOverlay extra={extra} showSelector={showSelector} />
        }
        closeSelector={() => setShowSelector(false)}
      />
    </div>
  )
}

interface VoucherSelectorOverlayProps {
  extra: ExtrasInfo
  showSelector: boolean
}

const VoucherSelectorOverlay = ({
  extra,
  showSelector,
}: VoucherSelectorOverlayProps) => {
  const { values, setValues } = useFormikContext<Order>()
  const isExtrasDisabled = extra.price === 0
  if (values.lessonType.length === 1) return null
  return (
    <div className='absolute top-[100%] z-50 flex flex-col gap-2 mt-1'>
      {/* Selected lessons */}
      {values.lessonType.map((lesson, index) => {
        const { voucherName, code, extras } = lesson

        if (lesson.extras?.id !== extra.id) return null

        return (
          <div
            className={clsx(
              'lg:w-168 w-114 h-34 rounded text-base font-title cursor-pointer text-center text-22 flex border  justify-center items-center relative ',
              isExtrasDisabled
                ? 'text-white bg-gray border-darkerGray'
                : 'text-black bg-yellow border-yellow',
            )}
            key={`${voucherName}-${code}-selected`}
          >
            <span>{voucherName}</span>

            {!isExtrasDisabled && extra.id === extras?.id && (
              <img
                src={cross}
                alt='Cross icon'
                className='absolute top-50% right-10'
                onClick={e => {
                  e.stopPropagation()
                  setValues(storedValues => {
                    const { extras, ...rest } = lesson
                    const updatedLessons = [...values.lessonType]
                    updatedLessons[index] = rest

                    return {
                      ...storedValues,
                      lessonType: updatedLessons,
                    }
                  })
                }}
              />
            )}
          </div>
        )
      })}
      {/* Not selected lessons */}
      {showSelector &&
        values.lessonType.map((lesson, index) => {
          const { voucherName, code } = lesson

          if (lesson.extras) return null

          return (
            <div
              className={clsx(
                'lg:w-168 w-114 h-34 rounded text-base font-title cursor-pointer text-center text-22 flex border border-yellow justify-center items-center relative text-white bg-black',
              )}
              key={`${voucherName}-${code}-notSelected`}
              onClick={() => {
                if (isExtrasDisabled) return
                setValues(storedValues => {
                  const lessonWithExtra = {
                    ...lesson,
                    extras: {
                      id: extra.id,
                      discountedPrice: extra.price,
                      quantity: 1,
                      name: extra.name,
                    },
                  }

                  const updatedLessons = [...values.lessonType]
                  updatedLessons[index] = lessonWithExtra

                  return {
                    ...storedValues,
                    lessonType: updatedLessons,
                  }
                })
              }}
            >
              <span>{voucherName}</span>
            </div>
          )
        })}
    </div>
  )
}
