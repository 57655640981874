import { AdminTable } from 'components/shared/AdminTable'
import { useQuery, useQueryClient } from 'react-query'
import { Branch } from 'types/types'
import { fetchBranches, updateLocation } from 'utils/requests'
import { getBranchesTableColumns } from 'utils/table-config'
import { getLocationsInitialData } from 'utils/utils'
import { Toast } from 'components/shared/Toast'
import { useToast } from '@chakra-ui/react'

export const AdminLocations = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: branches } = useQuery<Branch[]>('branches', fetchBranches)
  const columns = getBranchesTableColumns(queryClient)

  const updateBranchData = async (values: Branch) => {
    try {
      await updateLocation({
        ...values,
        map_url: values.map || '',
      })
      queryClient.invalidateQueries('branches')
      toast({
        position: 'top',
        status: 'success',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast status={'success'} title={'Změny byly úspěšně uloženy.'} />
        ),
      })
    } catch (error) {
      toast({
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast
            status={'error'}
            title={'Něco se nepovedlo - kontaktujte správce systému'}
          />
        ),
      })
    }
  }

  return (
    <div>
      {branches && (
        <AdminTable
          data={branches}
          columns={columns}
          isPaginated={false}
          isFilterable={false}
          getInitialData={getLocationsInitialData}
          updateFunction={updateBranchData}
        />
      )}
    </div>
  )
}
