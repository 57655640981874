import { Spinner } from '@chakra-ui/react'

export const LoadingSpinner = () => {
  return (
    <div className='w-full flex justify-center items-center grow xl:h-[50vh]'>
      <Spinner
        thickness='3px'
        speed='0.65s'
        emptyColor='gray.600'
        color='#ffea00'
        size='xl'
      />
    </div>
  )
}
