interface IconProps {
  fill?: string
}

export const Frisbee = ({ fill }: IconProps) => {
  return (
    <svg
      height='32px'
      viewBox='0 0 457 455'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M357.525 0.00374989C339.025 0.16375 317.025 5.02375 293.225 14.2437C242.425 33.9737 183.125 73.6237 128.425 128.324C73.6253 183.024 33.9653 242.424 14.3053 293.224C4.4153 318.624 -0.394694 341.824 0.0253061 361.124C0.455306 380.124 5.82531 394.924 16.0753 405.124C26.3353 415.424 41.0353 420.724 60.1353 421.224C79.3253 421.624 102.525 416.824 128.025 406.924C178.825 387.224 238.125 347.524 292.825 292.824C347.525 238.124 387.225 178.824 406.925 127.924C416.825 102.524 421.625 79.3238 421.225 60.1338C420.725 41.0438 415.425 26.3337 405.125 16.0837C394.825 5.82375 380.125 0.453751 361.125 0.0337506C359.925 0.00375058 358.725 -0.00625011 357.525 0.00374989ZM424.125 10.7038C434.025 24.0638 438.825 41.0038 439.225 59.7838C439.725 82.2238 434.225 107.524 423.625 134.424C402.825 188.424 361.825 249.324 305.625 305.524C249.325 361.824 188.425 402.724 134.425 423.624C107.525 434.224 82.2253 439.624 59.7753 439.224C44.7153 438.924 30.8453 435.724 19.0553 429.424C19.8553 430.324 20.6753 431.224 21.5253 432.024C47.4753 458.024 93.8253 461.424 149.825 442.724C205.825 424.124 270.125 383.524 328.025 325.524C385.925 267.624 426.525 203.424 445.125 147.424C463.925 91.4238 460.425 45.0738 434.425 19.1238C431.325 15.9838 427.825 13.1838 424.125 10.7038ZM271.725 92.1237C279.525 92.1237 287.025 94.4237 292.425 99.8237C299.125 106.524 300.925 116.224 299.825 125.924C298.825 135.624 295.125 146.124 289.525 157.524C278.325 180.324 259.125 206.524 234.025 231.624C208.925 256.724 182.725 275.924 159.925 287.124C148.625 292.724 138.025 296.424 128.325 297.424C118.625 298.524 108.925 296.724 102.225 290.024C95.5253 283.324 93.7253 273.624 94.8253 263.924C95.8253 254.224 99.5253 243.624 105.125 232.224C116.325 209.424 135.525 183.324 160.625 158.224C185.725 133.124 211.925 113.824 234.725 102.624C246.125 97.0237 256.625 93.4238 266.325 92.4238C268.125 92.2238 269.925 92.1237 271.725 92.1237Z'
        fill={fill || 'black'}
      />
    </svg>
  )
}
