import { AdminTable } from 'components/shared/AdminTable'
import { useQuery, useQueryClient } from 'react-query'
import { Discount, DiscountData } from 'types/types'
import { fetchDiscounts, updateDiscount } from 'utils/requests'
import { getDiscountsTableColumns } from 'utils/table-config'
import { getDiscountsInitialData } from 'utils/utils'
import { Toast } from 'components/shared/Toast'
import { useToast } from '@chakra-ui/react'

export const AdminDiscounts = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: discounts } = useQuery<Discount[]>('discounts', fetchDiscounts)

  const columns = getDiscountsTableColumns(queryClient)

  const updateDiscountData = async (values: DiscountData) => {
    try {
      await updateDiscount(values)
      queryClient.invalidateQueries('discounts')
      toast({
        position: 'top',
        status: 'success',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast status={'success'} title={'Změny byly úspěšně uloženy.'} />
        ),
      })
    } catch (error) {
      toast({
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
        render: () => (
          <Toast
            status={'error'}
            title={'Něco se nepovedlo - kontaktujte správce systému'}
          />
        ),
      })
    }
  }

  return (
    <div className='w-full'>
      {discounts && (
        <AdminTable
          data={discounts}
          columns={columns}
          isPaginated={false}
          isFilterable={false}
          getInitialData={getDiscountsInitialData}
          updateFunction={updateDiscountData}
        />
      )}
    </div>
  )
}
